<template>
  <div class="equipment bgFFF" id="ruleWrapper">
    <div class="e_content">
      <div class="ruleMessage1">
        <div class="title">计费规则信息</div>
        <el-form
          :model="formInline"
          :rules="rules2"
          ref="ruleForm2"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="f-top">
            <el-form-item :label="$t('searchModule.Rule_Name')" prop="feeName" :disabled="false">
              <el-input
                type="text"
                placeholder="请输入规则名称"
                v-model.trim="formInline.feeName"
                auto-complete="off"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item label="规则编码" prop="feeCode" :disabled="false">
              <el-input
                placeholder="请输入规则编码"
                v-model.trim="formInline.feeCode"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
              <el-select
                :disabled="this.isEdit"
                v-model.trim="formInline.operationId"
                filterable
                size="15"
              >
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="描述" prop="description">
              <el-input
                :maxlength="150"
                type="textarea"
                :rows="8"
                placeholder="请输入描述"
                v-model="formInline.description"
                style="width: 370px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="普通免费分钟数"
              prop="freeTime"
              label-width="130px"
            >
              <el-input
                placeholder="请输入免费分钟数"
                v-model.trim="formInline.freeTime"
                style="width: 200px"
              ></el-input>
              <span> 单位：分钟，免费停车时间</span>
            </el-form-item>
            <el-form-item
              label="24小时最高收费"
              label-width="130px"
              prop="limit24hour"
              :rules="[
                {
                  required: true,
                  message: '请输入金额',
                  trigger: 'blur',
                },
                { validator: checkNum, trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder="请输入24小时最高收费"
                v-model.trim="formInline.limit24hour"
                style="width: 200px"
              ></el-input>
              <span class="tips-span"> "0"表示没有最高限额</span>
            </el-form-item>
            <el-checkbox
              v-model="formInline.freeTimeAble"
              :true-label="0"
              :false-label="1"
              >免费分钟数不纳入计费时长</el-checkbox
            >
            <el-row>
              <el-col :span="4" style="margin-top: 10px">
                <el-checkbox v-model="isClickCar" @change="getClickCar"
                  >新能源车</el-checkbox
                >
              </el-col>
              <el-col :span="20">
                <el-form-item
                  label="免费分钟数"
                  prop="evFreeTime"
                  label-width="140px"
                  v-if="isClickCar"
                >
                  <el-input
                    placeholder="请输入免费分钟数"
                    v-model.trim="formInline.evFreeTime"
                    style="width: 200px"
                  ></el-input>
                  <span> 单位：分钟，免费停车时间</span>
                </el-form-item>
                <el-form-item
                  label="折扣系数"
                  prop="evCarDiscount"
                  label-width="140px"
                  v-if="isClickCar"
                >
                  <el-input
                    placeholder="请输入0-100的值"
                    v-model.trim="formInline.evCarDiscount"
                    style="width: 200px"
                  ></el-input>
                  <span> 单位：%</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" style="margin-top: 10px">
                <el-checkbox v-model="isDisableCar" @change="getDisableCar"
                  >残疾人车</el-checkbox
                >
              </el-col>
              <el-col :span="20">
                <el-form-item
                  label="免费分钟数"
                  prop="disabledFreeTime"
                  label-width="140px"
                  v-if="isDisableCar"
                >
                  <el-input
                    placeholder="请输入免费分钟数"
                    v-model.trim="formInline.disabledFreeTime"
                    style="width: 200px"
                  ></el-input>
                  <span> 单位：分钟，免费停车时间</span>
                </el-form-item>
                <el-form-item
                  label="折扣系数"
                  prop="disabledCarDiscount"
                  label-width="140px"
                  v-if="isDisableCar"
                >
                  <el-input
                    placeholder="请输入0-100的值"
                    v-model.trim="formInline.disabledCarDiscount"
                    style="width: 200px"
                  ></el-input>
                  <span> 单位：%</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div
            class="ruleMessage"
            v-for="(rule, index) in formInline.rules"
            :key="index"
          >
            <!-- 设置生效日期 -->
            <div class="timerPiker">
              <el-form-item
                label="生效日期"
                :rules="{ required: true, message: '生效日期不能为空' }"
              >
                <el-date-picker
                  :disabled="rule.dis"
                  v-model.trim="rule.cdate"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                  @change="timeChange(rule, index)"
                  :picker-options="pickerOptions0"
                >
                </el-date-picker>
                <span
                  v-if="!index"
                  @click="addRule(index)"
                  style="float: right"
                  class="addEffictDate"
                  >添加生效日期</span
                >
                <span
                  v-if="index && !rule.dis"
                  class="iconfont icon-shanchu-copy"
                  style="float: right"
                  @click="removeRule(rule)"
                  :style="{
                    'border-color': 'red',
                    color: 'red',
                    'font-size': '35px',
                  }"
                ></span>
              </el-form-item>
              <el-form-item label="" style="margin-left: 26%">
                <el-checkbox
                  v-model="rule.unitUp"
                  :true-label="1"
                  :false-label="0"
                  >出场时不满计费单元部分补齐计费单元收费</el-checkbox
                >
              </el-form-item>
              <div class="errMsg" style="padding-top: 5px">
                {{ rule.errMsg }}
              </div>
            </div>
            <!-- 设置时段 -->
            <div class="dateForm" style="margin-top: 50px">
              <div>
                <div class="weekdaysWrapper">
                  <div class="label">工作日计费规则：</div>
                  <div class="weekdays">
                    <div v-for="(detail, index1) in rule.details" :key="index1">
                      <el-form-item label="时段">
                        <el-tooltip placement="top" class="tooltip1">
                          <div slot="content">
                            时段：时段总和必须为24小时，例如：时段1+时段2 =
                            24小时 <br />
                          </div>
                          <span class="help">?</span>
                        </el-tooltip>
                        <el-time-picker
                          :disabled="rule.dis"
                          value-format="HH:mm"
                          format="HH:mm"
                          v-model="detail.tStart"
                          placeholder="任意时间点"
                          :picker-options="{ format: 'HH:mm' }"
                          @change="timeHandle(detail)"
                        >
                        </el-time-picker>
                        to
                        <el-time-picker
                          :disabled="rule.dis"
                          value-format="HH:mm"
                          format="HH:mm"
                          v-model="detail.tEnd"
                          placeholder="任意时间点"
                          :picker-options="{ format: 'HH:mm' }"
                          @change="timeHandle(detail)"
                        >
                        </el-time-picker>
                        <span
                          v-if="!rule.dis"
                          @click="
                            detailOpreate(index, index1, detail, 'details')
                          "
                          :class="
                            index1
                              ? 'iconfont icon-shanchu-copy'
                              : 'iconfont icon-jia'
                          "
                          :style="{
                            'border-color': index1 ? 'red' : 'blue',
                            color: index1 ? 'red' : 'blue',
                            'font-size': '30px',
                          }"
                        ></span>
                      </el-form-item>
                      <!-- 时段最高收费金额  模块 -->
                      <!-- <el-form-item
                        label="时段最高收费金额"
                        prop="limit24hour"
                        :disabled="rule.dis"
                        label-width="140px"
                      >
                        <el-input
                          placeholder="请输入时段最高收费金额"
                          v-model.trim="detail.timeChargeMax"
                          style="width: 200px"
                        ></el-input>
                        <span class="tips-span"> "0"表示没有最高限额</span>
                      </el-form-item> -->
                      <el-form-item
                        class="timeMax"
                        label="时段最高收费金额："
                        label-width="150px"
                        :prop="`rules[${index}].details[${index1}].limit`"
                        :rules="[
                          {
                            required: true,
                            message: '请输入金额',
                            trigger: 'blur',
                          },
                          { validator: checkNum, trigger: 'blur' },
                        ]"
                      >
                        <el-input
                          :disabled="rule.dis"
                          :maxlength="50"
                          v-model.trim="detail.limit"
                          style="width: 200px"
                          @input="
                            detail.limit = detail.limit.replace(
                              /[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g,
                              '$1'
                            )
                          "
                        ></el-input>
                        <span class="tips-span"> "0"表示没有最高限额</span>
                      </el-form-item>

                      <!-- 新计费区间计费 -->
                      <div class="range top">
                        <el-form-item
                          v-for="(item, index2) in detail.items"
                          :key="index2"
                          label=" "
                          label-width="5px"
                          :style="
                            detail.items.length > 2 &&
                            (index2 == 0 || index2 == detail.items.length - 1)
                              ? 'margin-left: 128px'
                              : ''
                          "
                          :rules="{ required: true, message: '必选' }"
                        >
                          <!-- 第一行 -->
                          <template
                            v-if="index2 == 0 && detail.items.length > 1"
                          >
                            <el-input
                              :disabled="rule.dis"
                              v-model="item.segment"
                              style="width: 100px; margin-right: 10px"
                            ></el-input>
                            <span
                              style="
                                font-size: 10px;
                                color: #aaaaaa;
                                margin-right: 10px;
                              "
                              >分钟内</span
                            >
                          </template>
                          <!-- 分钟中间范围 -->
                          <template
                            v-if="
                              detail.items.length > 2 &&
                              index2 != 0 &&
                              index2 != detail.items.length - 1
                            "
                          >
                            <el-input
                              v-if="index2 == 1"
                              :disabled="true"
                              v-model="
                                formInline.rules[index].details[index1].items[
                                  index2 - 1
                                ].segment
                              "
                              style="width: 100px; margin-right: 10px"
                            ></el-input>
                            <el-input
                              v-else
                              :disabled="true"
                              v-model="
                                formInline.rules[index].details[index1].items[
                                  index2 - 1
                                ].rangeRule
                              "
                              style="width: 100px; margin-right: 10px"
                            ></el-input>
                            <span style="margin-right: 10px">-</span>
                            <el-input
                              :disabled="rule.dis"
                              v-model="item.rangeRule"
                              style="width: 100px; margin-right: 10px"
                            ></el-input>
                            <span
                              style="
                                font-size: 10px;
                                color: #aaaaaa;
                                margin-right: 10px;
                              "
                              >分钟内</span
                            >
                          </template>
                          <!-- 最后一行 -->
                          <template
                            v-if="
                              index2 == detail.items.length - 1 && index2 != 0
                            "
                          >
                            <el-input
                              v-if="detail.items.length == 2"
                              :disabled="true"
                              v-model="
                                formInline.rules[index].details[index1].items[0]
                                  .segment
                              "
                              style="width: 100px; margin-right: 10px"
                            ></el-input>
                            <el-input
                              v-if="detail.items.length > 2"
                              :disabled="true"
                              v-model="
                                formInline.rules[index].details[index1].items[
                                  index2 - 1
                                ].rangeRule
                              "
                              style="width: 100px; margin-right: 10px"
                            ></el-input>
                            <span
                              style="
                                font-size: 10px;
                                color: #aaaaaa;
                                margin-right: 10px;
                              "
                              >分钟后</span
                            >
                          </template>
                          <el-select
                            :disabled="rule.dis"
                            v-model="item.type"
                            style="width: 110px; margin-right: 10px"
                          >
                            <el-option label="按次计费" :value="1"></el-option>
                            <el-option label="周期计价" :value="2"></el-option>
                          </el-select>
                          <el-input
                            :disabled="rule.dis"
                            v-model="item.unit"
                            style="width: 110px"
                            placeholder=""
                            @input="
                              item.unit = item.unit.replace(
                                /[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g,
                                '$1'
                              )
                            "
                          ></el-input>
                          <span>
                            元/ <span v-if="item.type == 1">次</span>
                            <el-input
                              :disabled="rule.dis"
                              v-if="item.type != 1"
                              v-model="item.period"
                              style="width: 100px; margin-left: 5px"
                            ></el-input
                            ><span v-if="item.type != 1"> 分钟 </span>
                          </span>
                          <span
                            v-if="!rule.dis"
                            v-show="index2 == detail.items.length - 1"
                            class="iconfont icon-jia"
                            style="
                              border-color: blue;
                              color: blue;
                              font-size: 30px;
                            "
                            @click="
                              newItemOpreate(
                                index,
                                index1,
                                index2,
                                item,
                                'details',
                                'add'
                              )
                            "
                          ></span>
                          <span
                            v-if="!rule.dis"
                            v-show="
                              index2 == detail.items.length - 1 &&
                              detail.items.length > 1
                            "
                            class="iconfont icon-shanchu-copy"
                            style="
                              border-color: red;
                              color: red;
                              font-size: 30px;
                            "
                            @click="
                              newItemOpreate(
                                index,
                                index1,
                                index2,
                                item,
                                'details',
                                'delete'
                              )
                            "
                          ></span>
                        </el-form-item>
                      </div>

                      <!-- 区间模块  :label="'区间' + (1 + index1) + '-' + (index2+1)"-->
                      <!-- <div
                        class="range top"
                        v-for="(item, index2) in detail.items"
                        :key="index2"
                      >
                        <el-form-item
                          :label="'区间' + (1 + index2)"
                          :rules="{ required: true, message: '必选' }"
                        >
                          <el-select
                            :disabled="rule.dis"
                            v-model="item.type"
                            style="width: 110px; margin-right: 10px"
                            @change="detailChange($event, detail, item)"
                          >
                            <el-option
                              label="基础时段"
                              :value="0"
                              v-show="
                                !detail.items[index2 - 1] ||
                                detail.items[index2 - 1].type != 0
                              "
                            ></el-option>
                            <el-option label="周期计价" :value="2"></el-option>
                            <el-option
                              label="按次计费"
                              :value="1"
                              v-show="
                                !detail.items[index2 - 1] ||
                                detail.items[index2 - 1].type != 0
                              "
                            ></el-option>
                          </el-select>
                          <span v-show="item.type !== 1">
                            <el-input
                              style="width: 110px"
                              :disabled="rule.dis"
                              maxlength="4"
                              @change="validateTime"
                              v-model.trim="item.segment"
                            ></el-input>
                            <span> 分钟 </span>
                          </span>
                          <el-input
                            :disabled="rule.dis"
                            v-model="item.unit"
                            style="width: 110px"
                            placeholder="区间单价"
                            @input="
                              item.unit = item.unit.replace(
                                /[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g,
                                '$1'
                              )
                            "
                          ></el-input>
                          <span>
                            元/ <span v-if="item.type == 1">次</span>
                            <el-input
                              :disabled="rule.dis"
                              v-if="item.type != 1"
                              v-model="item.period"
                              style="width: 100px; margin-left: 5px"
                            ></el-input>
                            <span v-if="item.type != 1"> 分钟 </span>
                          </span>
                          <span
                            v-if="!rule.dis"
                            @click="
                              itemOpreate(
                                index,
                                index1,
                                index2,
                                item,
                                'details'
                              )
                            "
                            v-show="
                              (detail.items.length != 2 &&
                                detail.items[0].type == 0) ||
                              index2 == 1
                            "
                            :class="
                              index2
                                ? 'iconfont icon-shanchu-copy'
                                : 'iconfont icon-jia'
                            "
                            :style="{
                              'border-color': index2 ? 'red' : 'blue',
                              color: index2 ? 'red' : 'blue',
                              'font-size': '30px',
                            }"
                          ></span>
                        </el-form-item>
                        <el-form-item
                          class="timeMax"
                          :prop="
                            'rules[' + index + '].details[' + index1 + '].limit'
                          "
                          label="时段最高收费金额："
                          label-width="150px"
                          v-if="detail.items[0].type != 1 && index2 === 1"
                          :rules="[
                            {
                              required: true,
                              message: '请输入金额',
                              trigger: 'blur',
                            },
                            { validator: checkNum, trigger: 'blur' },
                          ]"
                        >
                          <el-input
                            :disabled="rule.dis"
                            :maxlength="50"
                            v-model.trim="detail.limit"
                            style="width: 200px"
                            @input="
                              detail.limit = detail.limit.replace(
                                /[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g,
                                '$1'
                              )
                            "
                          ></el-input>
                          “0”表示没有最高限额
                        </el-form-item>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="weekdaysWrapper">
                  <div class="label">非工作日计费规则：</div>
                  <div class="holiday">
                    <el-select
                      style="margin-bottom: 20px"
                      v-model="rule.NOweekdays"
                      :disabled="rule.dis"
                      placeholder="请选择"
                      @change="weekdaysChange(index, $event)"
                    >
                      <el-option :value="1" label="同工作日计费规则"
                        >同工作日计费规则</el-option
                      >
                      <el-option :value="2" label="自定义">自定义</el-option>
                    </el-select>
                    <div v-if="rule.NOweekdays === 2">
                      <div
                        v-for="(detail, index1) in rule.holiday"
                        :key="index1"
                      >
                        <el-form-item label="时段">
                          <el-tooltip placement="top" class="tooltip1">
                            <div slot="content">
                              时段：时段总和必须为24小时，例如：时段1+时段2 =
                              24小时 <br />
                            </div>
                            <span class="help">?</span>
                          </el-tooltip>
                          <el-time-picker
                            :disabled="rule.dis"
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="detail.tStart"
                            placeholder="任意时间点"
                            :picker-options="{ format: 'HH:mm' }"
                            @change="timeHandle(detail)"
                          >
                          </el-time-picker>
                          to
                          <el-time-picker
                            :disabled="rule.dis"
                            value-format="HH:mm"
                            format="HH:mm"
                            v-model="detail.tEnd"
                            placeholder="任意时间点"
                            :picker-options="{ format: 'HH:mm' }"
                            @change="timeHandle(detail)"
                          >
                          </el-time-picker>
                          <!-- detailOpreate(index, index1, detail, 'holiday') -->
                          <span
                            v-if="!rule.dis"
                            @click="
                              detailOpreate(index, index1, detail, 'holiday')
                            "
                            :class="
                              index1
                                ? 'iconfont icon-shanchu-copy'
                                : 'iconfont icon-jia'
                            "
                            :style="{
                              'border-color': index1 ? 'red' : 'blue',
                              color: index1 ? 'red' : 'blue',
                              'font-size': '30px',
                            }"
                          ></span>
                        </el-form-item>
                        <el-form-item
                          class="timeMax"
                          label="时段最高收费金额："
                          label-width="150px"
                          :prop="`rules[${index}].holiday[${index1}].limit`"
                          :rules="[
                            {
                              required: true,
                              message: '请输入金额',
                              trigger: 'blur',
                            },
                            { validator: checkNum, trigger: 'blur' },
                          ]"
                        >
                          <el-input
                            :disabled="rule.dis"
                            :maxlength="50"
                            v-model.trim="detail.limit"
                            style="width: 200px"
                            @input="
                              detail.limit = detail.limit.replace(
                                /[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g,
                                '$1'
                              )
                            "
                          ></el-input>
                          <span class="tips-span"> "0"表示没有最高限额</span>
                        </el-form-item>
                        <!-- 新计费区间计费 -->
                        <div class="range top">
                          <el-form-item
                            v-for="(item, index2) in detail.items"
                            :key="index2"
                            label=" "
                            label-width="5px"
                            :style="
                              detail.items.length > 2 &&
                              (index2 == 0 || index2 == detail.items.length - 1)
                                ? 'margin-left: 128px'
                                : ''
                            "
                            :rules="{ required: true, message: '必选' }"
                          >
                            <!-- 第一行 -->
                            <template
                              v-if="index2 == 0 && detail.items.length > 1"
                            >
                              <el-input
                                :disabled="rule.dis"
                                v-model="item.segment"
                                style="width: 100px; margin-right: 10px"
                              ></el-input>
                              <span
                                style="
                                  font-size: 10px;
                                  color: #aaaaaa;
                                  margin-right: 10px;
                                "
                                >分钟内</span
                              >
                            </template>
                            <!-- 分钟中间范围 -->
                            <template
                              v-if="
                                detail.items.length > 2 &&
                                index2 != 0 &&
                                index2 != detail.items.length - 1
                              "
                            >
                              <el-input
                                v-if="index2 == 1"
                                :disabled="true"
                                v-model="
                                  formInline.rules[index].holiday[index1].items[
                                    index2 - 1
                                  ].segment
                                "
                                style="width: 100px; margin-right: 10px"
                              ></el-input>
                              <el-input
                                v-else
                                :disabled="true"
                                v-model="
                                  formInline.rules[index].holiday[index1].items[
                                    index2 - 1
                                  ].rangeRule
                                "
                                style="width: 100px; margin-right: 10px"
                              ></el-input>
                              <span style="margin-right: 10px">-</span>
                              <el-input
                                :disabled="rule.dis"
                                v-model="item.rangeRule"
                                style="width: 100px; margin-right: 10px"
                              ></el-input>
                              <span
                                style="
                                  font-size: 10px;
                                  color: #aaaaaa;
                                  margin-right: 10px;
                                "
                                >分钟内</span
                              >
                            </template>
                            <!-- 最后一行 -->
                            <template
                              v-if="
                                index2 == detail.items.length - 1 && index2 != 0
                              "
                            >
                              <el-input
                                v-if="detail.items.length == 2"
                                :disabled="true"
                                v-model="
                                  formInline.rules[index].holiday[index1]
                                    .items[0].segment
                                "
                                style="width: 100px; margin-right: 10px"
                              ></el-input>
                              <el-input
                                v-if="detail.items.length > 2"
                                :disabled="true"
                                v-model="
                                  formInline.rules[index].holiday[index1].items[
                                    index2 - 1
                                  ].rangeRule
                                "
                                style="width: 100px; margin-right: 10px"
                              ></el-input>
                              <span
                                style="
                                  font-size: 10px;
                                  color: #aaaaaa;
                                  margin-right: 10px;
                                "
                                >分钟后</span
                              >
                            </template>
                            <el-select
                              :disabled="rule.dis"
                              v-model="item.type"
                              style="width: 110px; margin-right: 10px"
                            >
                              <el-option
                                label="按次计费"
                                :value="1"
                              ></el-option>
                              <el-option
                                label="周期计价"
                                :value="2"
                              ></el-option>
                            </el-select>
                            <el-input
                              :disabled="rule.dis"
                              v-model="item.unit"
                              style="width: 110px"
                              placeholder=""
                              @input="
                                item.unit = item.unit.replace(
                                  /[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g,
                                  '$1'
                                )
                              "
                            ></el-input>
                            <span>
                              元/ <span v-if="item.type == 1">次</span>
                              <el-input
                                :disabled="rule.dis"
                                v-if="item.type != 1"
                                v-model="item.period"
                                style="width: 100px; margin-left: 5px"
                              ></el-input
                              ><span v-if="item.type != 1"> 分钟 </span>
                            </span>
                            <span
                              v-if="!rule.dis"
                              v-show="index2 == detail.items.length - 1"
                              class="iconfont icon-jia"
                              style="
                                border-color: blue;
                                color: blue;
                                font-size: 30px;
                              "
                              @click="
                                newItemOpreate(
                                  index,
                                  index1,
                                  index2,
                                  item,
                                  'holiday',
                                  'add'
                                )
                              "
                            ></span>
                            <span
                              v-if="!rule.dis"
                              v-show="
                                index2 == detail.items.length - 1 &&
                                detail.items.length > 1
                              "
                              class="iconfont icon-shanchu-copy"
                              style="
                                border-color: red;
                                color: red;
                                font-size: 30px;
                              "
                              @click="
                                newItemOpreate(
                                  index,
                                  index1,
                                  index2,
                                  item,
                                  'holiday',
                                  'delete'
                                )
                              "
                            ></span>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div style="margin: 0 auto; width: 200px; margin-bottom: 20px">
        <el-button type="primary" @click="submitData">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let checkFreeTime = (rule, value, callback) => {
      if (value && !/(\d+.\d+)|\d+/.test(value)) {
        callback(new Error("必须为数字"));
      } else if (value < 0 || value > 240) {
        callback(new Error("数字必须在0-240之间"));
      } else {
        callback();
      }
    };
    let evCarDiscount = (rule, value, callback) => {
      if (value && !/(\d+.\d+)|\d+/.test(value)) {
        callback(new Error("必须为数字"));
      } else if (value < 0 || value > 100) {
        callback(new Error("数字必须在0-100之间"));
      } else {
        callback();
      }
    };
    let checkCode = (rule, value, callback) => {
      value = value.replace(/\s/g, "");
      if (/[\u4E00-\u9FA5]/.test(value)) {
        return callback(new Error("编码不能存在汉字"));
      }
      if (!value) {
        callback(new Error("规则编码不能为空"));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error("规则编码长度在2-50之间"));
      } else {
        callback();
      }
    };
    let checkName = (rule, value, callback) => {
      var regEn = /[`~!@#$%^&*()_+<>?:"{},./;'[\]]/im,
        regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (!value) {
        callback(new Error("规则名称不能为空"));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error("规则名称长度在2-50之间"));
      } else if (regEn.test(value) || regCn.test(value)) {
        callback(new Error("名称不能包含特殊字符"));
      } else {
        callback();
        // this.$axios.get('/acb/2.0/fee/byFeeName/' + value).then(res => {
        //         if (res.state != 0) {
        //             if (this.isEdit && this.oldName == value) {
        //                 callback();
        //             } else {
        //                 callback(new Error('规则名称已经存在'));
        //             }
        //         } else {
        //             callback();
        //         }
        // })
      }
    };
    return {
      intervalTime: 0,
      isComputedIntervalTim: false,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      tenantList: [],
      oldFormInline: {},
      isNumber: false,
      timeCheck: true,
      first1: false,
      first2: false,
      isEdit: false,
      Dateflag: true,
      feeId: "",
      errMsg: "",
      equipmentId: "",
      formInline: {
        // id: 104,
        id: 150,
        operationId: "",
        description: "",
        feeName: "",
        freeTime: "0",
        evFreeTime: "",
        evCarDiscount: "",
        disabledFreeTime: "",
        disabledCarDiscount: "",
        feeCode: "",
        freeTimeAble: 1,
        limit24hour: "0",
        rules: [
          {
            NOweekdays: 1,
            errMsg: "",
            cid: "0",
            date: "",
            cdate: "",
            unitUp: 0,
            details: [
              {
                tStart: "",
                tEnd: "",
                cid: "0-0",
                start: "",
                end: "",
                limit: "0",
                // 新规则
                items: [
                  {
                    type: 1, // 选择框类型
                    unit: 0, // 元input
                    period: 0, // 分钟 input
                    segment: "60",
                    rangeRule: ''
                  },
                ],
              },
            ],
            holiday: [],
          },
        ],
      },
      rules2: {
        feeName: [
          {
            required: true,
            message: "请输入规则名称",
            trigger: "blur",
          },
          {
            validator: checkName,
            trigger: "blur",
          },
        ],
        feeCode: [
          {
            required: true,
            message: "请输入规则编码",
            trigger: "blur",
          },
          {
            validator: checkCode,
            trigger: "blur",
          },
        ],
        freeTime: [
          {
            required: true,
            message: "请输入免费分钟数",
            trigger: "blur",
          },
          {
            validator: checkFreeTime,
            trigger: "blur",
          },
        ],
        evFreeTime: [
          {
            required: true,
            message: "请输入免费分钟数",
            trigger: "blur",
          },
          {
            validator: checkFreeTime,
            trigger: "blur",
          },
        ],
        evCarDiscount: [
          {
            required: true,
            message: "请输入0-100的值",
            trigger: "blur",
          },
          {
            validator: evCarDiscount,
            trigger: "blur",
          },
        ],
        disabledFreeTime: [
          {
            required: true,
            message: "请输入免费分钟数",
            trigger: "blur",
          },
          {
            validator: checkFreeTime,
            trigger: "blur",
          },
        ],
        disabledCarDiscount: [
          {
            required: true,
            message: "请输入0-100的值",
            trigger: "blur",
          },
          {
            validator: evCarDiscount,
            trigger: "blur",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
      },
      isBindPark: 0,
      isClickCar: false,
      isDisableCar: false,
    };
  },
  methods: {
    getClickCar() {
      if (this.isClickCar) {
        this.rules2.evFreeTime[0].required = true;
        this.rules2.evCarDiscount[0].required = true;
      } else {
        this.rules2.evFreeTime[0].required = false;
        this.rules2.evCarDiscount[0].required = false;
        this.formInline.evFreeTime = "";
        this.formInline.evCarDiscount = "";
      }
    },
    getDisableCar() {
      if (this.isDisableCar) {
        this.rules2.disabledFreeTime[0].required = true;
        this.rules2.disabledCarDiscount[0].required = true;
      } else {
        this.rules2.disabledFreeTime[0].required = false;
        this.rules2.disabledCarDiscount[0].required = false;
        this.formInline.disabledFreeTime = "";
        this.formInline.disabledCarDiscount = "";
      }
    },
    weekdaysChange(index, val) {
      this.formInline.rules[index].holiday = [];
      if (val === 2) {
        this.formInline.rules[index].holiday.push({
          tStart: "",
          tEnd: "",
          cid: "0-0",
          start: "",
          end: "",
          limit: "0",
          items: [
            {
              type: 1, // 选择框类型
              unit: 0, // 元input
              period: 0, // 分钟 input
              segment: "60",
            },
          ],
        });
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    validateTime(value) {
      if (value === "") {
        this.isNumber = false;
        return;
      }
      value = Number(value);
      let num = /^[0-9]*$/;
      if (!num.test(value) || value > 1440 || value < 1) {
        this.isNumber = false;
      } else if (num.test(value) && value <= 1440) {
        this.isNumber = true;
      }
    },
    detailChange(value, detail, item) {
      item.period = 0;
      if (value != 0 && detail.items.length == 2) {
        detail.items.pop();
      }
    },
    checkEffectiveDate() {
      for (let i in this.formInline.rules) {
        if (
          this.formInline.rules[i].cdate == "" ||
          this.formInline.rules[i].cdate == undefined
        ) {
          this.formInline.rules[i].errMsg = "生效日期不能为空";
          return false;
        }
      }
      return true;
    },
    checkIsNull(data) {
      for (let i in data.rules) {
        for (let j in data.rules[i].details) {
          let detail = data.rules[i].details[j];
          if (detail.start == "") {
            this.$alert("时段开始时间不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (detail.end == "") {
            this.$alert("时段结束时间不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }

          let diff =
            (new Date("2018/1/1 " + detail.end) -
              new Date("2018/1/1 " + detail.start)) /
            1000 /
            60;
          if (detail.start == detail.end) {
            diff = 1440;
          }
          if (diff < 0) {
            diff = 1440 + diff;
          }
          let items = detail.items;
          let itemTotal = 0,
            periodStatus = false;
          for (let i in items) {
            if (items[i].period == 0 && items[i].type == 2) {
              periodStatus = 1;
            }
            if (items[i].unit == 0 && items[i].type == 2) {
              periodStatus = 2;
            }
            if (i == items.length - 1) break;
            if (items.length > 2 && items[i].segment < 0) {
              periodStatus = 3;
            }
            itemTotal += Number(items[i].segment);
          }
          if (periodStatus == 1) {
            this.$alert("周期计费分钟不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (periodStatus == 2) {
            this.$alert("周期计费金额不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (periodStatus == 3) {
            this.$alert("计费规则分钟为递增，请检查分钟数！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (Number(diff) < Number(itemTotal)) {
            this.$alert("计费分钟必须小于时段总分钟数！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }

          //   if (detail.start == detail.end) {
          //     diff = 1440;
          //   }
          //   if (diff < 0) {
          //     diff = 1440 + diff;
          //   }
          //   let items = detail.items;
          //   if (items.length == 1 && items[0].type == 0) {
          //     this.$alert("时段下至少有一个周期时长或按次计费！!", this.$t('pop_up.Tips'), {
          //       confirmButtonText: this.$t('pop_up.Determine'),
          //     });
          //     return false;
          //   }
          //   for (let i in items) {
          //     if (items[i].unit == 0 && items[i].type != 1) {
          //       this.$alert("区间单价不能为空或为0！！", this.$t('pop_up.Tips'), {
          //         confirmButtonText: this.$t('pop_up.Determine'),
          //       });
          //       return false;
          //     }
          //     if (items[i].period == 0) {
          //       this.$alert("区间分钟不能为空或为0！！", this.$t('pop_up.Tips'), {
          //         confirmButtonText: this.$t('pop_up.Determine'),
          //       });
          //       return false;
          //     }
          //     if (parseInt(items[i].period) != items[i].period) {
          //       this.$alert("区间分钟必须为整数！！", this.$t('pop_up.Tips'), {
          //         confirmButtonText: this.$t('pop_up.Determine'),
          //       });
          //       return false;
          //     }
          //     if (items[i].period > diff) {
          //       this.$alert("区间分钟必须小于时段总分钟数！！", this.$t('pop_up.Tips'), {
          //         confirmButtonText: this.$t('pop_up.Determine'),
          //       });
          //       return false;
          //     }
          //     if (
          //       items[i].type == 2 &&
          //       i == 1 &&
          //       items[i].period > diff - items[0].period
          //     ) {
          //       this.$alert(
          //         "同一时段下，基础时段和周期计价分钟总和不能大于该时段总分钟数！",
          //         this.$t('pop_up.Tips'),
          //         {
          //           confirmButtonText: this.$t('pop_up.Determine'),
          //         }
          //       );
          //       return false;
          //     }
          //     if (
          //       !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(
          //         items[i].unit
          //       )
          //     ) {
          //       if (items[i].type == 1 && items[i].unit == 0) {
          //         return true;
          //       } else {
          //         this.$alert("金额必须为数字，且最多两位小数！！");
          //         return false;
          //       }
          //     }
          //   }
        }
        
        for (let j in data.rules[i].holiday) {
          let detail = data.rules[i].holiday[j];
          if (detail.start == "") {
            this.$alert("时段开始时间不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (detail.end == "") {
            this.$alert("时段结束时间不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }

          let diff =
            (new Date("2018/1/1 " + detail.end) -
              new Date("2018/1/1 " + detail.start)) /
            1000 /
            60;
          if (detail.start == detail.end) {
            diff = 1440;
          }
          if (diff < 0) {
            diff = 1440 + diff;
          }
          let items = detail.items;
          let itemTotal = 0,
            periodStatus = false;
          for (let i in items) {
            if (items[i].period == 0 && items[i].type == 2) {
              periodStatus = 1;
            }
            if (items[i].unit == 0 && items[i].type == 2) {
              periodStatus = 2;
            }
            if (i == items.length - 1) break;
            if (items.length > 2 && items[i].segment < 0) {
              periodStatus = 3;
            }
            itemTotal += Number(items[i].segment);
          }
          if (periodStatus == 1) {
            this.$alert("周期计费分钟不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (periodStatus == 2) {
            this.$alert("周期计费金额不能为空或为0！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (periodStatus == 3) {
            this.$alert("计费规则分钟为递增，请检查分钟数！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          if (Number(diff) < Number(itemTotal)) {
            this.$alert("计费分钟必须小于时段总分钟数！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
        }
      }
      return true;
    },
    sendHandle() {
      let data = Object.assign({}, this.formInline);
      data.freeTime = data.freeTime ? parseInt(data.freeTime) : "";
      data.evFreeTime = data.evFreeTime !== "" ? parseInt(data.evFreeTime) : "";
      data.evCarDiscount =
        data.evCarDiscount !== "" ? parseInt(data.evCarDiscount) : "";
      data.disabledFreeTime =
        data.disabledFreeTime !== "" ? parseInt(data.disabledFreeTime) : "";
      data.disabledCarDiscount =
        data.disabledCarDiscount !== ""
          ? parseInt(data.disabledCarDiscount)
          : "";
      this.errMsg = true;
      for (let i in data.rules) {
        delete data.rules[i].cid;
        delete data.rules[i].errMsg;
        // delete data.rules[i].cdate;
        delete data.rules[i].dis;
        // if (!this.rulesDataFormatter(data.rules[i].details)) return false;
        // if (data.rules[i].holiday.length) {
        //   if (!this.rulesDataFormatter(data.rules[i].holiday)) return false;
        // }
        this.handleSendInit(data.rules[i].details)
        if (data.rules[i].holiday.length) {
          this.handleSendInit(data.rules[i].holiday)
        }
      }
      this.checkTime(data);
      return data;
    },
    handleSendInit(details) {
        for (let y in details) {
          let detail = details[y];
          let diff =
            (new Date("2018/1/1 " + detail.end) -
              new Date("2018/1/1 " + detail.start)) /
            1000 /
            60;
          if (detail.start == detail.end) {
            diff = 1440;
          }
          if (diff < 0) {
            diff = 1440 + diff;
          }
          let items = detail.items;
          let itemTotal = 0;
          for (let x in items) {
            if (x == items.length - 1) break;
            if (x != 0) {
              if (x == 1) {
                items[x].segment = Number(items[x].rangeRule) - Number(items[x - 1].segment);
              } else {
                items[x].segment = Number(items[x].rangeRule) - Number(items[x - 1].rangeRule);
              } 
            }
            itemTotal += Number(items[x].segment);
          }
          for (let z = 0; z < items.length; z++) {
            if (z == items.length - 1) {
              items[z].segment = Number(diff) - Number(itemTotal);
            }
            if (items[z].type == 1) {
              items[z].period = items[z].segment;
            }
          }
        }
    },
    rulesDataFormatter(arr) {
      this.intervalTime = 0;
      this.isComputedIntervalTime = true;
      for (let j in arr) {
        // delete arr[j].tStart;
        // delete arr[j].tEnd;
        delete arr[j].cid;
        for (let k in arr[j].items) {
          arr[j].items[k].segment =
            arr[j].items[k].segment || arr[j].items[k].segment === 0
              ? parseInt(arr[j].items[k].segment)
              : "";
          arr[j].items[k].period = arr[j].items[k].period * 1;
          arr[j].items[k].type = arr[j].items[k].type * 1;
          if (arr[j].items[k].type == 1) {
            arr[j].items[k].segment = 0;
            this.isComputedIntervalTime = false;
          }
          if (arr[j].items[k].period == 0 && arr[j].items[k].type == 2) {
            this.timeCheck = false;
          }
          delete arr[j].items[k].cid;
          this.intervalTime += arr[j].items[k].segment;
        }
      }
      if (this.isComputedIntervalTime && this.intervalTime !== 1440) {
        this.$alert("区间填写必须为数字并且总和为1440");
        return false;
      } else {
        return true;
      }
    },
    checkTime(da) {
      let data = Object.assign({}, da);
      for (let i in data.rules) {
        this.checkTimeItem(data.rules[i].details);
        if (data.rules[i].holiday.length) {
          this.checkTimeItem(data.rules[i].holiday);
        }
        da = data;
      }
    },
    checkTimeItem(arr) {
      let timePeriod = 0;
      for (let j in arr) {
        let timePeriod_ = 0;
        if (
          new Date("2017-1-1 " + arr[j].end).getTime() ===
          new Date("2017-1-1 " + arr[j].start).getTime()
        ) {
          timePeriod_ = 24 * 60 * 60;
        } else {
          if (
            new Date("2017-1-1 " + arr[j].end).getTime() ===
            new Date("2017-1-1 00:00:00").getTime()
          ) {
            timePeriod_ =
              1 +
              Math.abs(
                new Date("2017-1-1 23:59:59").getTime() -
                  new Date("2017-1-1 " + arr[j].start).getTime()
              ) /
                1000;
          } else {
            timePeriod_ =
              Math.abs(
                1 *
                  (new Date("2017-1-1 " + arr[j].end).getTime() -
                    new Date("2017-1-1 " + arr[j].start).getTime())
              ) / 1000;
            if (
              1 *
                (new Date("2017-1-1 " + arr[j].end).getTime() -
                  new Date("2017-1-1 " + arr[j].start).getTime()) <
              0
            ) {
              timePeriod_ = 0 - timePeriod_ + 24 * 60 * 60;
            }
          }
        }
        // if (arr[j].items[0].type == 1) {
        //   arr[j].items[0].period = timePeriod_ / 60;
        // }
        timePeriod += timePeriod_;
      }
      if (timePeriod != 24 * 60 * 60) {
        this.timeCheck = false;
      }
    },
    setScroll() {
      this.$nextTick(() => {
        let doc = document.getElementById("content");
        doc.scrollTop = doc.scrollHeight - doc.clientHeight;
      });
    },
    checkDate(rule, index) {
      let curDate = rule.cdate;
      let arr = [...this.formInline.rules];
      let tem = arr.splice(index + 1);
      // 当前日期大于后面日期
      let col = tem.filter((value, index) => {
        return new Date(curDate) - new Date(value.cdate) >= 0;
      });
      if (col.length) {
        rule.errMsg = "日期必须小于后面的日期！";
        return false;
      }
      arr = [...this.formInline.rules];
      tem = arr.splice(0, index);
      col = tem.filter((value, index) => {
        return new Date(curDate) - new Date(value.cdate) <= 0;
      });
      if (col.length) {
        rule.errMsg = "日期必须大于前面的日期！";
        return false;
      }
      return true;
    },

    timeChange(rule, index) {
      if (rule.cdate == "" || rule.cdate == undefined) {
        rule.errMsg = "生效日期不能为空";
        this.errMsg = false;
        return;
      } else {
        rule.errMsg = "";
        this.errMsg = true;
      }
      rule.date = rule.cdate.split(" ")[0];
      rule.time = rule.cdate.split(" ")[1];
      this.errMsg = this.checkDate(rule, index);
    },
    checkNum(rule, value, callback) {
      if (value == 0) {
        return callback();
      }
      if (!/(\d+.\d+)|\d+/.test(value)) {
        return callback(new Error("必须为数字"));
      }
      if (!value) {
        callback(new Error("最高限额不能为空"));
      } else if (value < 0) {
        callback(new Error("最高收费限额应在0-999之间"));
      } else if (value > 999) {
        callback(new Error("最高收费限额应在0-999之间"));
      } else {
        callback();
      }
    },
    timeHandle(detail) {
      if (detail.tStart && detail.tStart.split(":")[2] != "00") {
        let tem = detail.tStart.split(":");
        tem[2] = "00";
        detail.tStart = tem.join(":");
      }
      if (detail.tEnd && detail.tEnd.split(":")[2] != "00") {
        let tem = detail.tEnd.split(":");
        tem[2] = "00";
        detail.tEnd = tem.join(":");
      }
      detail.start = !detail.tStart ? "" : detail.tStart;
      detail.end = !detail.tEnd ? "" : detail.tEnd;
    },
    dateFormat() {},
    removeRule(item) {
      var index = this.formInline.rules.indexOf(item);
      if (index !== -1) {
        this.formInline.rules.splice(index, 1);
      }
    },
    addRule(index) {
      this.formInline.rules.push({
        NOweekdays: 1,
        cid: "form" + index,
        date: "",
        errMsg: "",
        cdate: "",
        details: [
          {
            cid: "form" + index,
            tStart: "",
            tEnd: "",
            start: "",
            end: "",
            limit: "0",
            items: [
              {
                type: 1, // 选择框类型
                unit: 0, // 元input
                period: 0, // 分钟 input
                segment: "", // 开始分钟数
              },
            ],
          },
        ],
        holiday: [],
      });
      // this.setScroll();
    },
    detailOpreate(index, index1, detail, tag) {
      if (this.formInline.rules[index].dis) {
        return;
      }
      if (!index1) {
        this.formInline.rules[index][tag].push({
          tStart: "",
          tEnd: "",
          start: "",
          end: "",
          limit: "0",
          items: [
            {
              type: 1, // 选择框类型
              unit: 0, // 元input
              period: 0, // 分钟 input
              segment: "", // 开始分钟数
            },
          ],
        });
      } else {
        var ind = this.formInline.rules[index][tag].indexOf(detail);
        if (ind !== -1) {
          this.formInline.rules[index][tag].splice(ind);
        }
      }
    },
    itemOpreate(index, index1, index2, item, tag) {
      if (this.formInline.rules[index].dis) {
        return;
      }
      if (!index2) {
        this.formInline.rules[index][tag][index1].items.push({
          cid: "form" + index,
          type: 2,
          segment: 0,
          threshold: 0,
          period: 0,
          unit: 0,
        });
      } else {
        var ind = this.formInline.rules[index][tag][index1].items.indexOf(item);
        if (ind !== -1) {
          this.formInline.rules[index][tag][index1].items.splice(ind, 1);
        }
      }
    },
    newItemOpreate(index, index1, index2, item, tag, type) {
      if (this.formInline.rules[index].dis) {
        return;
      }
      let items = this.formInline.rules[index][tag][index1].items;
      if (type == "add") {
        if (items.length < 2) {
          items.push({
            type: 1, // 选择框类型
            unit: 0, // 元input
            period: 0, // 分钟 input
            segment: "", // 开始分钟数
            rangeRule: '' // 范围分钟数
          });
        } else {
          items.splice(items.length - 1, 0, {
            type: 1, // 选择框类型
            unit: 0, // 元input
            period: 0, // 分钟 input
            segment: "", // 开始分钟数
            rangeRule: '' // 范围分钟数
          });
        }
      } else {
        if (items.length < 3) {
          items.splice(items.length - 1, 1);
        } else {
          items.splice(items.length - 2, 1);
        }
      }
    },
    // 校验新规则
    handleNewRuleCheck(tag) {
      const rules = this.formInline.rules;
      let status = true;
      for (let i = 0; i < rules.length; i++) {
        let details = rules[i][tag];
        for (let y = 0; y < details.length; y++) {
          let items = details[y].items;
          for (let z = 0; z < items.length; z++) {
            let newItem = items[z];
            if (newItem.type == 1 && newItem.unit === "") {
              status = false;
              break;
            }
            if (
              newItem.type == 2 &&
              (newItem.unit === "" || newItem.period === "")
            ) {
              status = false;
              break;
            }
            if (items.length > 1) {
              if (z == 0 && newItem.segment === "") {
                status = false;
                break;
              } else if (
                items.length > 2 &&
                z != items.length - 1 &&
                z != 0 &&
                newItem.rangeRule === ""
              ) {
                status = false;
                break;
              }
            }
          }
        }
      }
      return status;
    },
    submitData() {
      this.Dateflag = false;
      this.$refs.ruleForm2.validate((valid) => {
        // 校验生效日期是否填写
        if (this.checkEffectiveDate()) {
        } else {
          this.$alert("请检查生效日期是否正确");
          return;
        }

        if (!this.handleNewRuleCheck('details')) {
          this.$alert("工作日计费规则不能为空");
          return;
        }
        if (!this.handleNewRuleCheck('holiday')) {
          this.$alert("非工作日计费规则不能为空");
          return;
        }

        let data2 = null;
        // if (!this.sendHandle()) {
        //   return;
        // } else {
        data2 = this.sendHandle();
        // }
        setTimeout(() => {
          data2.feeCode = data2.feeCode.replace(/\s/g, "");
          if (!this.checkIsNull(data2)) {
            this.timeCheck = true;
            this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
            return;
          }
          if (valid) {
            if (!this.timeCheck) {
              this.$alert("同一生效日期内，时段之和必须为24小时！");
              this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
              this.timeCheck = true;
              return;
            }
            this.ruleSort(data2.rules);
            let data1 = {
              description: data2.description,
              feeName: data2.feeName,
              freeTime: data2.freeTime,
              feeCode: data2.feeCode,
              operationId: data2.operationId,
              evFreeTime: data2.evFreeTime,
              evCarDiscount: data2.evCarDiscount,
              disabledFreeTime: data2.disabledFreeTime,
              disabledCarDiscount: data2.disabledCarDiscount,
              freeTimeAble: data2.freeTimeAble,
              source: JSON.stringify({
                id: data2.id,
                freeTime: data2.freeTime,
                evFreeTime: data2.evFreeTime,
                evCarDiscount: data2.evCarDiscount,
                disabledFreeTime: data2.disabledFreeTime,
                disabledCarDiscount: data2.disabledCarDiscount,
                freeTimeAble: data2.freeTimeAble,
                limit24hour: Number(data2.limit24hour),
                rules: data2.rules,
              }),
            };
            console.log(data2.rules)
            let method = this.isEdit ? 'post' : "post";
            let url = !this.isEdit
              ? "/acb/2.0/fee/create"
              : "/acb/2.0/fee/update/" + this.feeId;
            this.$axios[method](url, {
              data: data1,
            }).then((res) => {
              if (res.state == 0) {
                this.$router.go(-1);
              } else {
                // this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
                this.$alert(res.desc);
              }
            });
          } else {
            if (this.errMsg != true) {
              this.$alert("提交失败，请保证信息无误", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
            this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
            this.Dateflag = true;
            return false;
          }
        }, 300);
      });
    },
    getRuleHandle(data, value) {
      if (data.evCarDiscount !== "" || data.evFreeTime !== "") {
        this.isClickCar = true;
      }
      if (data.disabledFreeTime !== "" || data.disabledCarDiscount !== "") {
        this.isDisableCar = true;
      }
      data.freeTime = data.freeTime + "";
      for (let i in data.rules) {
        data.rules[i].cid = "0-0-0";
        data.rules[i].errMsg = "";
        data.rules[i].cdate = data.rules[i].date + " " + data.rules[i].time;
        if (i < this.isBind) {
          data.rules[i].dis = true;
        }
        for (let j in data.rules[i].details) {
          data.rules[i].details[j].tStart = !data.rules[i].details[j].start
            ? ""
            : data.rules[i].details[j].start;
          data.rules[i].details[j].tEnd = !data.rules[i].details[j].end
            ? ""
            : data.rules[i].details[j].end;

          for (let k in data.rules[i].details[j].items) {
            data.rules[i].details[j].items[k].type = Number(
              data.rules[i].details[j].items[k].type
            );
          }
        }
        for (let j in data.rules[i].holiday) {
          data.rules[i].holiday[j].tStart = !data.rules[i].holiday[j].start
            ? ""
            : data.rules[i].holiday[j].start;
          data.rules[i].holiday[j].tEnd = !data.rules[i].holiday[j].end
            ? ""
            : data.rules[i].holiday[j].end;
          for (let k in data.rules[i].holiday[j].items) {
            data.rules[i].holiday[j].items[k].type = Number(
              data.rules[i].holiday[j].items[k].type
            );
          }
        }
      }
      this.formInline = Object.assign(
        {
          operationId: value.operationId,
          feeName: value.feeName,
          feeCode: value.feeCode,
          description: value.description,
        },
        data
      );
      // console.log(this.formInline);
    },
    ruleSort(rules) {
      rules.forEach((rule) => {
        rule.details = rule.details.sort((detail1, detail2) => {
          return (
            new Date("2018/1/1 " + detail1.start) -
            new Date("2018/1/1 " + detail2.start)
          );
        });
      });
    },
    getRule() {
      this.$axios.get("/acb/2.0/fee/byId/" + this.feeId).then((res) => {
        if (res.state == 0) {
          if (res.value.isBind) {
            this.isBind = JSON.parse(res.value.property).rules.length;
          }
          let obj = res.value;
          obj.description = obj.description ? obj.description : "";
          let rule = JSON.parse(res.value.property);
          this.getRuleHandle(rule, res.value);
          this.oldName = res.value.feeName;
          this.oldCode = res.value.feeCode;
          rule.rules.forEach((item, index) => {
            if (!item.NOweekdays) this.formInline.rules[index].NOweekdays = 1;
          });
          this.isBindPark = obj.isBind || 0;
        } else {
          this.$alert(res.desc);
        }
      });
    },
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    if (this.$route.query.feeId) {
      // console.log(this.$route.query, 'this.$route.querythis.$route.query')
      this.isEdit = true;
      this.first1 = true;
      this.first2 = 2;
      this.feeId = this.$route.query.feeId;
      this.getRule();
    }
  },
};
</script>
<style scoped>
.tooltip1 {
  position: absolute !important;
  left: -65px;
  top: 8px;
  z-index: 1;
}

.fuhao {
  display: inline-block;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 16px;
  text-decoration: none;
  border: 2px solid;
  border-color: blue;
  font-size: 30px;
  vertical-align: middle;
}

.equipment .e_content {
  border: 1px solid #c0ccda;
  border-radius: 4px;
  min-height: 550px;
  padding: 0 20px;
  color: #48576a;
}

.equipment .e_content .f-top {
  width: 700px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.el-select {
  width: 250px;
}

.breadcrumb {
  height: 35px;
}

.ruleMessage {
  padding: 10px;
  /* border: 1px solid #c0ccda; */
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 20px;
}

.title {
  line-height: 40px;
  padding-left: 20px;
}

.timerPiker {
  padding-left: 20px;
  position: relative;
}

.timerPiker .errMsg {
  position: absolute;
  left: 120px;
  top: 39px;
  font-size: 12px;
  color: #ff4949;
}

.effectDate {
  margin-right: 10px;
}

.dateForm {
  width: 1000px;
  margin: 0 auto;
}

.dateForm .iconfont {
  position: relative;
  left: 10px;
  top: 5px;
}

.dateForm .weekdaysWrapper {
  display: flex;
}

.dateForm .weekdaysWrapper .weekdays,
.dateForm .weekdaysWrapper .holiday {
  flex: 1;
}

.dateForm .label {
  padding: 16px 20px 0 0;
}

b {
  text-align: center;
  font-weight: normal;
  display: inline-block;
  width: 36px;
  line-height: 36px;
  background: #c0ccda;
}

.addEffictDate {
  display: inline-block;
  cursor: pointer;
}

.addEffictDate:hover {
  color: blue;
}

.help {
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 20px;
  line-height: 20px;
  vertical-align: middle;
  background: #c0ccda;
  position: relative;
}

.timeMax label {
  padding: 0 !important;
}

/* .selected label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
} */
span.tips-span {
  color: #aaaaaa;
}
</style>
